<template>
    <div class="display-managment">
        <div class="display-managment-page">
            <div class="display-managment-page-title">
                <i @click="$emit('close')" class="material-icons close-icon">close</i>
                <h2 style="font-weight: 400;">{{managment.name}}</h2>
            </div>
            <div class="display-managment-page-content">
                <section class="display-managment-page-description">
                    <p>{{managment.description}}</p>
                </section>
    
                <h2>קבצי נספחים:</h2>
                <h3 v-if="!managment.attachments?.length">לא קיימים</h3>
                <div style="margin-bottom: 10px; border-bottom: 1px solid lightgray;" class="display-managment-page-content-images">
                    <template v-for="attachment in managment.attachments" :key="attachment.url">
                        <div style="cursor: pointer; user-select: none;" class="attachment-image">
                            <div class="image">
                                <a :href="attachment.url" target="_blank" rel="noopener noreferrer">
                                    <img :src=" fileTypeIcons.get(attachment.type)" alt="תמונה">
                                </a>
                            </div>
                            <div class="file-name">
                                <p>{{attachment.name}}</p>
                            </div>
                        </div>
                    </template>
                </div>
                <h2>גלריית תמונות:</h2>
                <h3 v-if="!managment.images?.length">לא קיימים</h3>
                <div class="display-managment-page-content-images">
                    <template v-for="image in managment.images" :key="image.url">
                        <div class="image">
                            <img :src="image.url" alt="תמונה">
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
export default {
    emits:['close'],
    props:['managment'],
    setup () {
        

        const fileTypeIcons = ref(new Map([
            ["Word", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fdoc_375x375.png?alt=media&token=d4f50ee5-3ea8-461e-aaf1-f554abf9a714"],
            ["Excel", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fxls_375x375.png?alt=media&token=5b534af5-6e3d-41e6-8a1a-abc627211353"],
            ["PDF", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fpdf_375x375.png?alt=media&token=13037383-f80c-4669-ae8a-ce85f354fc2f"],
            ["Google Docs", "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/files_types%2Fdoc_375x375.png?alt=media&token=d4f50ee5-3ea8-461e-aaf1-f554abf9a714"]
        ]));


        return {

            fileTypeIcons
        }
    }
}
</script>

<style scoped>
    .display-managment{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        color: #333;
    }
    .display-managment-page{
        width: 100%;
        max-width: 900px;
        height: 100%;
        margin: 0 auto;
        background: #fff;
        border-radius: 5px;
    }
    .display-managment-page-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgrey;
    }
    .display-managment-page-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
        padding: 20px;
    }

    .display-managment-page-description{
        width: 100%;
    }
    .display-managment-page-description > p{
        white-space: pre-wrap;
        font-size: 20px;
    }

    .display-managment-page-content-images{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        padding: 10px;
    }
    .display-managment-page-content-images > .image{
        position: relative;
        width: calc((100% / 3) - 2px);
        border: 1px solid lightblue;
    }

    .display-managment-page-content-images > .image > img{
        width: 100%;
    }

    .display-managment-page-content-images > .attachment-image {
        position: relative;
        width: calc((100% / 3) - 2px);
        height: 100px;
        border: 1px solid lightblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .display-managment-page-content-images > .attachment-image > .image {
        width: 100%;
        height: 70px; /* Adjusted to leave space for file name and delete icon */
        position: relative;
    }

    .display-managment-page-content-images > .attachment-image > .image > a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .display-managment-page-content-images > .attachment-image > .image > a > img {
        max-width: 80%; /* Adjusted to fit better */
        max-height: 80%;
    }

    .display-managment-page-content-images > .attachment-image > .file-name {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 12px;
        color: #333;
        height: 30px;
    }

    .close-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        color: grey;
        cursor: pointer;
        user-select: none;
    }
</style>